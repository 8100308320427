import React from 'react';
import { Link } from 'gatsby';
import { useCoins } from './use-coins';
import './cta.scss';

interface Props {
  extended?: boolean;
}

export default ({ extended }: Props) => {
  const coins = useCoins(7, 7);

  return (
    <section className="home__cta">
      <div className="coins">
        {coins.map(coin => (
          <img
            key={coin.id}
            id={`coin-${coin.id}`}
            className="coin"
            style={{
              top: `${coin.y}%`,
              left: `${coin.x}%`,
              width: coin.width,
              height: coin.height,
            }}
            src={coin.img}
          />
        ))}
      </div>
      <div
        className="content cta"
        style={extended ? {} : { paddingTop: '50px' }}
      >
        {extended && (
          <p>
            Introducing <strong>Lease or Leave</strong>: A tool designed to guide you
            out of your car lease and into a better financial future.
          </p>
        )}
        <Link to="/get-started" className="btn">
          Get Started
        </Link>
      </div>
    </section>
  );
};
