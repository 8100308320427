import { useState, useEffect, useRef } from 'react';
import coin from '../images/falling-money-large.svg';
import blankCoin from '../images/falling-money-small.svg';

interface Coin {
  id: number;
  x: number;
  y: number;
  width: number;
  height: number;
  img: string;
  seen: boolean;
}

const sizes = [
  { width: 16, height: 16, img: blankCoin },
  { width: 16, height: 16, img: blankCoin },
  { width: 16, height: 16, img: blankCoin },
  { width: 16, height: 16, img: blankCoin },
  { width: 32, height: 32, img: coin },
  { width: 32, height: 32, img: coin },
  { width: 32, height: 32, img: coin },
  { width: 70, height: 70, img: coin },
];

function getRandomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max + 1 - min)) + min;
}

let id = 0;
function getRandomCoin(): Coin {
  return {
    id: id++,
    x: getRandomInt(8, 92),
    y: getRandomInt(0, 25),
    seen: false,
    ...sizes[getRandomInt(0, sizes.length - 1)],
  };
}

function getRandomCoins(count: number) {
  // TODO: prevent coins stacking on top of each other
  // TODO: limit to 2 or 3 large coins
  return new Array(count).fill(0).map(_ => getRandomCoin());
}

export function useCoins(startingCoinCount: number, tossCount: number) {
  const [coins, setCoins] = useState(getRandomCoins(startingCoinCount));
  const tick = useRef<NodeJS.Timeout | null>(null);

  const toss = () => {
    setCoins(oldCoins => [
      ...oldCoins.filter(c => !c.seen).map(c => ({ ...c, seen: true })),
      ...getRandomCoins(tossCount),
    ]);
  };

  useEffect(() => {
    tick.current = setInterval(toss, 2000);

    return () => {
      if (tick.current) clearInterval(tick.current);
    };
  }, [tick]);

  return coins;
}
