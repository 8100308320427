import React from 'react';
import './about.scss';

import Layout from '../components/layout';
import SEO from '../components/seo';
import CTA from '../components/cta';

export default () => (
  <Layout>
    <SEO title="Our Purpose" />
    <div className="constrain">
      <div className="about">
        <h3 className="about__heading">Our Purpose</h3>
        <p className="about__hero">
          Lease or Leave exists to help people live debt free lives.
        </p>
        <div className="about__paragraphs">
          <p>
            Lease or Leave was founded on the simple idea that life is better without debt.
            Houses, cars, credit cards. Everything. We think you should own your
            things. Not the other way around. So, we decided to start building
            tools to help.
          </p>
        </div>
      </div>
      <CTA />
    </div>
  </Layout>
);
